<template>
  <div class="home">
    <div class="news-HImg">
      <img src="@/assets/images/1551929445.jpg" />
    </div>
    <div class="news-HCont">
      <nav class="navbar navbar-expand-lg">
        <ul class="navbar-nav">
          <li
            class="nav-item"
            v-for="(aboutType, index) in websiteAboutType"
            :key="index"
          >
            <router-link
              class="nav-link"
              :to="{ path: '/about?about_type=' + aboutType.dictValue }"
              >{{ aboutType.dictLabel }}</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="container">
      <section class="met-editor clearfix" v-if="about != null">
        <div style="white-space: pre-wrap" v-html="about.content"></div>
      </section>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "About",
  data() {
    return {
      //路由中网站的关于我们
      aboutType: null,
      //关于我们信息
      about: null,
      //网站关于我们的类型
      websiteAboutType: null,
    };
  },
  mounted() {
    var _this = this;
    //获取新闻类型
    _this.aboutType = this.$route.query.about_type || 1;
    //获取关于我们信息
    _this.getAbout();
  },
  methods: {
    getAbout() {
      var _this = this;
      var aboutType = _this.aboutType;
      //ajax获取新闻信息
      _this.$axios
        .get("/system/about/details/" + aboutType)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.about = response.data.data.about;
            _this.websiteAboutType = response.data.data.about_type;
          }
        });
    },
    newsMenuChoose() {
      var _this = this;
      //菜单选中
      var fullPath = "#" + _this.$route.fullPath;
      $(".news-HCont").find(".navbar-nav").find("li").removeClass("active");
      $(".news-HCont")
        .find(".navbar-nav")
        .find("a")
        .each(function (index, element) {
          var path = $(element).attr("href");
          if (path == fullPath) {
            $(element).parent("li").addClass("active");
          }
        });
    },
  },
  watch: {
    //监听路由改变
    $route(to) {
      var _this = this;
      //判断关于我们类型是否有改变，若有改变重新加载数据
      if (_this.aboutType != to.query.about_type) {
        _this.aboutType = to.query.about_type;
        //获取关于我们信息
        _this.getAbout();
        //关于我们菜单选中
        _this.newsMenuChoose();
      }
    },
  },
  updated: function () {
    this.$nextTick(function () {
      var _this = this;
      //关于我们菜单选中
      _this.newsMenuChoose();
    });
  },
};
</script>
<style scoped>
strong {
  font-weight: bold;
}
.home {
  background-color: #ffffff;
  color: #2a333c;
}
.news-HImg img {
  width: 100%;
  height: 200px;
}
.news-HCont {
  width: 100%;
  display: block;
  background-color: #ffffff;
}
.news-HCont .navbar-nav .active > .nav-link {
  color: #00c9a1 !important;
}
.news-HCont .navbar {
  border-bottom: 1px solid #e5e7ea;
}
.news-HCont .navbar-nav {
  margin: 0px auto;
  padding: 15px 0px;
}
.news-HCont .navbar-nav a {
  color: #555555 !important;
  font-size: 16px;
}
.navbar-nav .dropdown-item:hover a {
  color: #00c9a1 !important;
}
.news-HCont .dropdown-menu {
  background-color: #ffffff !important;
  line-height: 30px;
}

.met-editor {
  margin: 30px 0px;
  font-size: 16px;
}
.container {
  min-height: 250px;
}
</style>
<style>
.met-editor p {
  margin-bottom: 0.2rem !important;
}
</style>
